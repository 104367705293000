<template>
  <div
    class="product-card"
    :class="{ intersected: isIntersecting, thumb }"
    ref="el"
    v-if="media.length"
  >
    <a class="product-card__link" :aria-label="product.title" :href="`/products/${product.handle}`">
      <ProductCardMedia :media="media" />
      <div class="product-card__caption">
        <h2 class="product-card__title"><ProductCaptionTitle :title="product.title" /></h2>
        <ProductCardPrices class="prices" />
      </div>
    </a>
  </div>
</template>

<script lang="ts" setup>
import ProductCaptionTitle from './ProductCardTitle.vue'
import ProductCardMedia from './ProductCardMedia.vue'
import { ref, withDefaults } from 'vue'
import useIntersection from '../../../composables/useIntersection'
import ProductCardPrices from './ProductCardPrices.vue'
import { ProductFieldsFragment } from '_library/src/storefront/generated'
import { useProductContext } from '../../../context/useProductContext'

const el = ref(null)
const { isIntersecting } = useIntersection(el)

const props = withDefaults(
  defineProps<{
    product: ProductFieldsFragment
    thumb?: boolean
  }>(),
  {
    thumb: false,
  }
)

const productContext = useProductContext({ product: props.product })

const { media } = productContext
</script>

<style lang="scss" scoped>
@import '~styles/base';

.product-card {
  --caption-height: #{lineHeights(2)};

  transition: var(--transition-intersection);
  position: relative;
  display: flex;

  aspect-ratio: 450 / 615;

  &:not(.intersected) {
    opacity: 0;
  }

  &__link {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 0;
  }

  &__title {
    flex: 1;
    text-decoration: none;
    @include breakpoint('xl') {
      min-height: lineHeights(2);
    }
  }

  &__caption {
    display: flex;
    width: 100%;
    margin-top: 15px;

    @include breakpoint('xl') {
      display: block;
    }

    @media (min-width: 900px) {
      position: absolute;
      top: 100%;
      left: 0;
    }
  }

  @media (hover: hover) {
    &.thumb {
      .product-card__caption {
        opacity: 0;
      }

      &:focus,
      &:hover {
        .product-card__caption {
          opacity: 1;
        }
      }
    }
  }
}
</style>
